import * as types from "./types";

/**
 * Simbangda Action Call
 */
const monthNameIndo = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Des",
};

const getRealisasiBelanjaOPD = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_REALISASI_BELANJA_OPD_START });
  tahun = "" ? new Date().getFullYear() : tahun;
  const response = await Api.getRealisasiBelanjaOPD(tahun);

  if (response.status !== "failed") {
    let nameMonth = [];
    let grafikBarTargetFisik = [];
    let grafikBarTargetKeuangan = [];
    let grafikSplineRealisasiFisik = [];
    let grafikSplineRealisasiKeuangan = [];
    let realDataGrafik = response?.result?.response?.data?.grafik_akumulasi;

    for (const items of realDataGrafik) {
      nameMonth.push(monthNameIndo[items.bulan]);
      grafikBarTargetFisik.push(items.target_fisik);
      grafikBarTargetKeuangan.push(items.target_keuangan);
      grafikSplineRealisasiFisik.push(items.realisasi_fisik);
      grafikSplineRealisasiKeuangan.push(items.realisasi_keuangan);
    }


    let grafikDataMap = {
      nameMonth,
      grafikBarTargetFisik,
      grafikBarTargetKeuangan,
      grafikSplineRealisasiFisik,
      grafikSplineRealisasiKeuangan,
    };

    dispatch({
      type: types.GET_REALISASI_BELANJA_OPD_SUCCESS,
      payload: {
        data: response.result.response.data,
        timestamp: response.timestamp,
        grafikData: grafikDataMap,
      },
    });
  } else {
    dispatch({
      type: types.GET_REALISASI_BELANJA_OPD_FAILED,
      payload: response.message,
    });
  }
};

const getGrafikSimbangda = (idopd, tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_GRAFIK_SIMBANGDA_START });
  const response = await Api.getgrafikSimbangda(idopd, tahun);

  if (response.status !== "failed") {
    let nameMonth = [];
    let grafikBarTargetFisik = [];
    let grafikBarTargetKeuangan = [];
    let grafikSplineRealisasiFisik = [];
    let grafikSplineRealisasiKeuangan = [];
    let realDataGrafik = idopd !== 'all' ? response.result.response.data : response.result.data;

    for (const items of realDataGrafik) {
      nameMonth.push(monthNameIndo[items.bulan]);
      grafikBarTargetFisik.push(items.target_fisik);
      grafikBarTargetKeuangan.push(items.target_keuangan);
      grafikSplineRealisasiFisik.push(items.realisasi_fisik);
      grafikSplineRealisasiKeuangan.push(items.realisasi_keuangan);
    }

    let grafikDataMap = {
      nameMonth,
      grafikBarTargetFisik,
      grafikBarTargetKeuangan,
      grafikSplineRealisasiFisik,
      grafikSplineRealisasiKeuangan,
    };

    dispatch({
      type: types.GET_GRAFIK_SIMBANGDA_SUCCESS,
      payload: { data: grafikDataMap, timestamp: response.timestamp },
    });
  } else {
    dispatch({
      type: types.GET_GRAFIK_SIMBANGDA_FAILED,
      payload: "WWWWWWWWWW",
    });
  }
};

const getRealisasiKegiatanOPD = (idopd, tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_REALISASI_KEGIATAN_PER_OPD_START });
  const response = await Api.getRealisasiKegiatanPerOPD(idopd, tahun);

  if (response.status !== "failed") {
    let temp = [];

    if (typeof response.result === "undefined") {
      dispatch({
        type: types.GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS,
        payload: [],
      });
    } else {
      let dataResponse = response.result.data;
      for (const itemsProgram of dataResponse) {
        temp.push({
          nama: itemsProgram.nama_program,
          level: 1,
          isParent: true,
          ...itemsProgram,
        });
        for (const itemsKegiatan of itemsProgram.data_kegiatan) {
          temp.push({
            nama: itemsKegiatan.nama_kegiatan,
            level: 2,
            isParent:
              itemsProgram.data_kegiatan[0].nama_kegiatan ===
              itemsKegiatan.nama_kegiatan
                ? true
                : false,
            ...itemsKegiatan,
          });
          for (const itemsSubKegiatan of itemsKegiatan.data_sub_kegiatan) {
            temp.push({
              nama: itemsSubKegiatan.nama_sub_kegiatan,
              level: 3,
              isParent: false,
              ...itemsSubKegiatan,
            });
          }
        }
      }
      dispatch({
        type: types.GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS,
        payload: { data: temp, timestamp: response.timestamp },
      });
    }
  } else {
    dispatch({
      type: types.GET_REALISASI_KEGIATAN_PER_OPD_FAILED,
      payload: response.message,
    });
  }
};

const getRealisasiBelanjaPerOPD = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_REALISASI_BELANJA_PER_OPD_START });
  const response = await Api.getRealisasiBelanjaPerOPD(tahun);

  if (response.status !== "failed") {
    let totalPagu = 0;
    let totalKuanganTargetRp = 0;
    let totalRealisasiKeuangan = 0;
    const result = response.result;
    for (const items of result.data) {
      totalPagu += parseInt(items.pagu);
      totalKuanganTargetRp += parseInt(items.rp_target_keuangan);
      totalRealisasiKeuangan += parseInt(items.rp_realisasi_keuangan);
    }
    let footerData = {
      totalPagu,
      totalKuanganTargetRp,
      totalRealisasiKeuangan,
    };
    dispatch({
      type: types.GET_REALISASI_BELANJA_PER_OPD_SUCCESS,
      payload: {
        data: result.data,
        pencapaian_opd: result.pencapaian_opd,
        totalFooter: footerData,
        timestamp: response.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_REALISASI_BELANJA_PER_OPD_FAILED,
      payload: response.message,
    });
  }
};

const getRealisasiBelanjaPerOPD_bybulan =
  (bulan = "", tahun = "") =>
  async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_REALISASI_BELANJA_PER_OPD_START });
    const response = await Api.getRealisasiBelanjaOPD_perbulan(bulan, tahun);

    if (response.status !== "failed") {
      let totalPagu = 0;
      let totalKuanganTargetRp = 0;
      let totalRealisasiKeuangan = 0;
      const result = response;
      for (const items of result.data) {
        totalPagu += parseInt(items.pagu);
        totalKuanganTargetRp += parseInt(items.rp_target_keuangan);
        totalRealisasiKeuangan += parseInt(items.rp_realisasi_keuangan);
      }
      let footerData = {
        totalPagu,
        totalKuanganTargetRp,
        totalRealisasiKeuangan,
      };

      dispatch({
        type: types.GET_REALISASI_BELANJA_PER_OPD_SUCCESS,
        payload: {
          data: result.data,
          pencapaian_opd: result.pencapaian_opd,
          totalFooter: footerData,
          timestamp: response.timestamp,
        },
      });
    } else {
      dispatch({
        type: types.GET_REALISASI_BELANJA_PER_OPD_FAILED,
        payload: response.message,
      });
    }
  };

/**
 * Bappenda Action Call
 */
const getPendapatanDaerah = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_PENDAPATAN_DAERAH_START });
  tahun = "" ? new Date().getFullYear() : tahun;
  const response = await Api.getPendapatanDaerah(tahun);
  if (response.status !== "failed") {
    dispatch({
      type: types.GET_PENDAPATAN_DAERAH_SUCCESS,
      payload: {
        data: response.data.result,
        timestamp: response.data.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_PENDAPATAN_DAERAH_FAILED,
      payload: response.message,
    });
  }
};

const getGrafikPendapatan = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_GRAFIK_PENDAPATAN_START,
  });
  const response = await Api.getGrafikPendapatan(tahun);
  if (response.status !== "failed") {
    let timestamp = response.data.timestamp;
    let data = response.data.result.response;
    let opd = [];
    let value = [];
    for (const items of data) {
      opd.push(items.unit);
      value.push(items.persentase);
    }
    dispatch({
      type: types.GET_GRAFIK_PENDAPATAN_SUCCESS,
      payload: { opd, value, timestamp },
    });
  } else {
    dispatch({
      type: types.GET_GRAFIK_PENDAPATAN_FAILED,
    });
  }
};

const getAllPendapatan = (tahun = "") => async (dispatch, getState, Api) => {
  try {
    dispatch({ type: types.GET_ALL_PENDAPATAN_START });
    const response = await Api.getAllPendapatan(tahun);
    // let dataResponse = response.data.result.response.find((item) => {
    //   const d = new Date();
    //   let year = d.getFullYear();
    //   if (item.tahun == year) return item.data;
    // }); //2023 jika 0 2022 jika 1 2023
    let dataResponse = response.data.result.response[0]
    dataResponse = dataResponse?.data; //2023 jika 0 2022 jika 1 2023
    let tempParent = [];

    for (const items of dataResponse) {
      if (items.kode.length == 4) {
        // kita check big parent, disini pembedanya dari huruf besar atau pakai kode length 4 jika
        if (items.kode !== "4.") {
          tempParent.push({
            ...items,
            kode: items.kode,
            last_update: response.data.timestamp,
            child: [],
          });
        }
      } else {
        for (const itemsParents of tempParent) {
          if (items.kode.includes(itemsParents.kode)) {
            // kita check kode parent dengan kode anak nya

            if (items.kode.length < 8) {
              // hitung length
              itemsParents.child.push({
                ...items,
                kode: items.kode,
                last_update: response.data.timestamp,
              });
            }
          }
        }
      }
    }

    let tableData = [];
    for (const [keys, iterator] of dataResponse.entries()) {
      tableData.push({
        ...iterator,
        after: [],
      });
      for (const iteratorAfter of dataResponse) {
        if (iteratorAfter.kode == iterator.kode) {
          tableData[keys].after.push(iteratorAfter);
        }
      }
    }
    dispatch({
      type: types.GET_ALL_PENDAPATAN_SUCCESS,
      payload: {
        mapping: tempParent,
        realData: response.data.response,
        tableData,
        last_update: response.data.timestamp,
      },
    });
  } catch (err) {
    dispatch({ type: types.GET_ALL_PENDAPATAN_FAILED });
  }
};

/**
 * PBJ Action Call
 */
const getRealisasiPbjProses = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({ type: types.GET_REALISASI_PBJ_START });
  tahun = "" ? new Date().getFullYear() : tahun
  const response = await Api.getRealisasiProsesPbj(tahun);

  if (response.status !== "failed") {
    let timestamp = response.timestamp;
    let temp = [];
    let tendering = response.result.data.Tendering;
    let nonTendering = response.result.data.Nontendering;
    let ePurchasing = response.result.data.Ekatalog;
    let nonEpurchasingAndNonTendering = {
      ...nonTendering,
    };

    let proxyName = {
      paket: "Paket",
      nilai: "Nilai",
      belum_proses: "Belum Proses",
      sedang_proses: "Sedang Proses",
      selesai_proses: "Selesai Proses",
      selesai_pelaksanaan: "Selesai Pelaksanaan",
      // sudah: 'Sudah',
    };

    let tempEpurchasing = [];
    for (const keysItems of Object.keys(ePurchasing)) {
      if (keysItems !== "sudah") {
        tempEpurchasing.push({
          title: proxyName[keysItems],
          value: ePurchasing[keysItems],
          last_update: timestamp,
        });
      }
    }

    temp.push({
      title: "E-Purchasing",
      bg: "bg-primaryOrange",
      bgFooter: "bg-primeblue",
      data: tempEpurchasing,
      last_update: timestamp,
      type: 3,
    });

    let tempTendering = [];
    for (const keysItems of Object.keys(tendering)) {
      if (keysItems !== "sudah") {
        tempTendering.push({
          title: proxyName[keysItems],
          value: tendering[keysItems],
          last_update: timestamp,
        });
      }
    }

    temp.push({
      title: "Tendering",
      bg: "bg-primaryOrange",
      bgFooter: "bg-primeblue",
      data: tempTendering,
      last_update: timestamp,
      type: 1,
    });

    let tempNonEpurchasingAndNonTendering = [];
    for (const keysItems of Object.keys(nonEpurchasingAndNonTendering)) {
      if (keysItems !== "sudah") {
        tempNonEpurchasingAndNonTendering.push({
          title: proxyName[keysItems],
          value: nonEpurchasingAndNonTendering[keysItems],
          last_update: timestamp,
        });
      }
    }

    temp.push({
      title: "Non-Tendering & Non E-Purchasing",
      bg: "bg-primaryOrange",
      bgFooter: "bg-primeblue",
      data: tempNonEpurchasingAndNonTendering,
      last_update: timestamp,
      type: 2,
    });

    /**
     * TODO : data hibah harcode
     */
    let tempHibah = [];
    for (const items of Object.keys(proxyName)) {
      tempHibah.push({
        title: proxyName[items],
        value: 0,
      });
    }

    // temp.push({
    //   title: 'Hibah',
    //   bg: 'bg-primaryGreen',
    //   bgFooter: 'bg-green-500',
    //   data: tempHibah,
    //   last_update: 'n/a',
    //   type: 0,
    // });

    dispatch({
      type: types.GET_REALISASI_PBJ_SUCCESS,
      payload: temp,
    });
  } else {
    dispatch({
      type: types.GET_REALISASI_PBJ_FAILED,
    });
  }
};

const getRealisasiOpdPbj = (type, tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_REALISASI_OPD_PBJ_START,
  });
  const response = await Api.getRealisasiOpdPbj(type, tahun);
  if (response.status !== "failed") {
    let timestamp = response.data.timestamp;
    console.log("times", timestamp);
    dispatch({
      type: types.GET_REALISASI_OPD_PBJ_SUCCESS,
      payload: { data: response.data.result.data, timestamp },
    });
  } else {
    dispatch({
      type: types.GET_REALISASI_OPD_PBJ_FAILED,
    });
  }
};

const getDetailPakerOpdPbj = (idsatker, tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_PAKET_OPD_PBJ_START,
  });
  const response = await Api.getDetailPaketOpdPbj(idsatker, tahun);
  if (response.status !== "failed") {
    console.log("response success detail paker", response.data.timestamp);
    dispatch({
      type: types.GET_PAKET_OPD_PBJ_SUCCESS,
      payload: {
        data: response.data.result.data,
        timestamp: response.data.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_PAKET_OPD_PBJ_FAILED,
    });
  }
};

const getDataHibah = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_DATA_HIBAH_START,
  });
  const response = await Api.getDataHibah(tahun);
  if (response.status !== "failed") {
    dispatch({
      type: types.GET_DATA_HIBAH_SUCCESS,
      payload: {
        data: response.data.result.list,
        timestamp: response.data.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_DATA_HIBAH_FAILED,
    });
  }
};
const getOpdPemberiHibah = (tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_OPD_PEMBERI_HIBAH_START,
  });
  const response = await Api.getOpdPemberiHibah(tahun);
  if (response.status !== "failed") {
    dispatch({
      type: types.GET_OPD_PEMBERI_HIBAH_SUCCESS,
      payload: {
        data: response.data.result,
        timestamp: response.data.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_OPD_PEMBERI_HIBAH_FAILED,
    });
  }
};
const getDetailHibahOpd = (opdId, tahun = "") => async (dispatch, getState, Api) => {
  dispatch({
    type: types.GET_DETAIL_HIBAH_OPD_START,
  });
  const response = await Api.getDetailHibahOpd(opdId, tahun);
  if (response.status !== "failed") {
    dispatch({
      type: types.GET_DETAIL_HIBAH_OPD_SUCCESS,
      payload: {
        data: response.data.result.list,
        timestamp: response.data.timestamp,
      },
    });
  } else {
    dispatch({
      type: types.GET_DETAIL_HIBAH_OPD_FAILED,
    });
  }
};

export {
  getRealisasiBelanjaOPD,
  getPendapatanDaerah,
  getAllPendapatan,
  getRealisasiBelanjaPerOPD,
  getRealisasiPbjProses,
  getRealisasiOpdPbj,
  getDetailPakerOpdPbj,
  getRealisasiKegiatanOPD,
  getGrafikSimbangda,
  getGrafikPendapatan,
  getDataHibah,
  getOpdPemberiHibah,
  getDetailHibahOpd,
  getRealisasiBelanjaPerOPD_bybulan,
};
