import React from "react";
import HeaderNav from "./HeaderNav";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";

import Modal from "../Modal";

const BreadCrumbs = ({ data }) => {
  return (
    <div className="py-3 px-5 flex flex-row space-x-1">
      {data.map((items, keys) => {
        return (
          <>
            {items.isActive ? (
              <>
                <Link
                  to={items.goTo}
                  className="text-primaryGreen text-sm font-bold"
                >
                  {items.title}
                </Link>
                <div className="text-md"> {">"}</div>
              </>
            ) : (
              <p className="text-gray-700 text-sm font-bold">{items.title}</p>
            )}
          </>
        );
      })}
      <button onClick={() => bcktoTop()} className="bck-btn md:p-2 p-1 md:w-[47px] w-8 md:h-[47px] h-8 z-30 bottom-[85px] md:bottom-[100px] shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="md:w-8 w-6 md:h-8 h-6 text-white  transform rotate-90">
          <path
              fill-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
              clip-rule="evenodd"
          />
          </svg>
      </button>
    </div>
  );
};

const bcktoTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
}

const Layout = ({
  children,
  breadCrumbsData = [],
  loading = false,
  popUpShow = false,
  closePopUp = () => {},
}) => {
  const _renderBreadCrumbs = () => {
    return (
      <>
        {breadCrumbsData.length !== 0 ? (
          breadCrumbsData.length !== 1 ? (
            <BreadCrumbs data={breadCrumbsData} />
          ) : (
            <div className="py-3"></div>
          )
        ) : (
          <div className="py-3"></div>
        )}
      </>
    );
  };

  const fullYear = () => new Date().getFullYear();
  //get current url
  
  const pathname = window.location.pathname;

  return (
    <LoadingOverlay
      active={loading}
      spinner={<BeatLoader color={"#36d7b7"} />}
      text="Mohon menunggu..."
    >
      <Modal open={popUpShow} close={closePopUp} />
      <main className="bg-[#F2F9F1] min-h-screen mb-14">
        <HeaderNav />
        {_renderBreadCrumbs()}
        <div className="px-3">{children}</div>
        <footer
          className="bg-gray-100  mb-0 flex justify-between sm:flex-row flex-col text-gray-700 border-t-2 fixed
              inset-x-0
              bottom-0  border-gray-300 p-2 text-xs "
        >
          <div className="flex flex-col">
            <span className="sr-only">Space Kosong</span>
          </div>
          <div className="font-bold text-center">
            <span className="">
              © {fullYear()} Copyright{" "}
              <a
                href="https://sumbarprov.go.id/"
                target="_blank"
                className="text-red-700"
              >
                Pemerintah Provinsi Sumatera Barat
              </a>
            </span>{" "}
            <br /> <span style={{ color: "#819E43" }}>Dashboard Pembangunan</span> v
            2.1.1 <br />
            {/* Powered by{" "}
            <a
              href="https://diskominfotik.sumbarprov.go.id/"
              target="_blank"
              className="text-blue-500"
            >
              Diskominfotik
            </a> */}
          </div>
          
          <div className="flex w-auto justify-end">
              {
                pathname === "/hotline" ? null :
              
                            <Link to="/hotline" className="sm:block hidden">
                                <div className="text-gray-700 flex flex-row space-x-2 relative justify-center items-center h-full ">
                                    <div className="p-2 bg-green-400 rounded-full text-white absolute top-0 -left-2">
                                        <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                        </svg>
                                    </div>
                                    <div className="p-2 bg-green-400 rounded-full text-white animate-ping absolute top-0 -left-4">
                                        <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                        </svg>
                                    </div>
                                    <div className="bg-green-400 py-1 px-4 rounded-full text-white text-xs">
                                      Hotline Pengaduan
                                    </div>
                                </div>
                            </Link>
              }
          </div>
          {
            pathname === "/hotline" ? null : 
            <Link to="/hotline" className="sm:hidden block absolute -top-10 left-4">
              <div className="text-gray-700 flex flex-row space-x-2 relative justify-center items-center h-full">
                    <div className="p-2 bg-green-400 rounded-full border-4 border-white text-white absolute top-0 -left-2">
                        <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>
                    </div>
                    <div className="p-2 bg-green-400 rounded-full border-4 border-yellow-400 text-yellow-400 animate-ping absolute top-0 -left-4">
                        <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>
                    </div>
              </div>
            </Link>
          }
          
        </footer>
          
      </main>
    </LoadingOverlay>
  );
};

export default Layout;
