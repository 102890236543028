import * as types from './types';

const initialState = {
  pendapatanDaerah: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    target: 0,
    realisasi: 0,
    persentase: 0,
    last_update: '',
  },
  grafikPendapatanDaerah: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: {
      opd: [],
      value: [],
    },
    last_update: '',
  },
  realisasiBelanjaOPD: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    target: 0,
    realisasi: 0,
    persentase: 0,
    last_update: '',
    grafikDataSimbangda: {},
  },
  allPendapatan: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    realData: [],
    tableData: [],
    last_update: '',
  },
  relisasiPerOpd: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    pencapaian_opd: {
      pagu: '0',
      rp_target_keuangan: '',
      persen_target_keuangan: 0,
      rp_realisasi_keuangan: '0',
      persen_realisasi_keuangan: 0,
      target_fisik_akumulasi: 0,
      realisasi_fisik_akumulasi: 0,
      deviasi_fisik_akumulasi: 0,
      target_fisik_bulanan: 0,
      target_keuangan_bulanan: 0,
      persen_target_keuangan_bulanan: 0,
    },
    totalFooter: {},
    timestamp: '',
  },
  realisasiPbjProses: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
  },
  pbjDetailOpd: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    last_update: '',
  },
  pbjDetailPaketOpd: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    last_update: '',
  },
  simbangdaKegiatanOpd: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    timestamp: '',
  },
  grafikSimbangda: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: {
      nameMonth: [],
      grafikBarTargetFisik: [],
      grafikBarTargetKeuangan: [],
      grafikSplineRealisasiFisik: [],
      grafikSplineRealisasiKeuangan: [],
    },
    last_update: '',
  },
  dataHibah:{
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: {
      jumlah: 0,
      nilai: 0,
      belum_proses: 0,
      proses: 0,
      selesai: 0,
    },
    last_update: 'n/a'
  },
  opdPemberiHibah: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    last_update: 'n/a'
  },
  detailHibahOpd: {
    loadingGet: false,
    errorGet: false,
    messageGet: '',
    data: [],
    last_update: 'n/a'
  }
};

export default function homeReducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_REALISASI_BELANJA_OPD_START:
      return {
        ...state,
        realisasiBelanjaOPD: {
          loadingGet: true,
          errorGet: false,
          messageGet: 'waiting',
          target: 0,
          realisasi: 0,
          persentase: 0,
          last_update: '',
        },
      };
    case types.GET_REALISASI_BELANJA_OPD_SUCCESS:
      console.log('aaaaa', actions.payload);
      return {
        ...state,
        realisasiBelanjaOPD: {
          loadingGet: false,
          errorGet: false,
          messageGet: 'success',
          target: actions.payload.data.pagu_total,
          realisasi: actions.payload.data.rp_realisasi_keuangan_total,
          persentase: actions.payload.data.persen_realisasi,
          last_update: actions.payload.timestamp,
          grafikDataSimbangda: actions.payload.grafikData,
        },
      };
    case types.GET_REALISASI_BELANJA_OPD_FAILED:
      return {
        ...state,
        realisasiBelanjaOPD: {
          loadingGet: false,
          errorGet: true,
          messageGet: 'failed',
          target: 'n/a',
          realisasi: 'n/a',
          persentase: 'n/a',
          last_update: '',
        },
      };

    case types.GET_PENDAPATAN_DAERAH_START:
      return {
        ...state,
        pendapatanDaerah: {
          loadingGet: true,
          errorGet: false,
          messageGet: 'waiting',
          target: 0,
          realisasi: 0,
          persentase: 0,
          last_update: '',
        },
      };
    case types.GET_PENDAPATAN_DAERAH_SUCCESS:
      return {
        ...state,
        pendapatanDaerah: {
          loadingGet: false,
          errorGet: false,
          messageGet: 'success',
          target: actions.payload.data.response.target,
          realisasi: actions.payload.data.response.realisasi,
          persentase: actions.payload.data.response.persentase,
          last_update: actions.payload.timestamp,
        },
      };
    case types.GET_PENDAPATAN_DAERAH_FAILED:
      return {
        ...state,
        pendapatanDaerah: {
          loadingGet: false,
          errorGet: true,
          messageGet: 'failed',
          target: 'n/a',
          realisasi: 'n/a',
          persentase: 'n/a',
          last_update: '',
        },
      };
    case types.GET_ALL_PENDAPATAN_START:
      return {
        ...state,
        allPendapatan: {
          ...initialState.allPendapatan,
          loadingGet: true,
        },
      };
    case types.GET_ALL_PENDAPATAN_SUCCESS:
      return {
        ...state,
        allPendapatan: {
          ...initialState.allPendapatan,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data',
          data: actions.payload.mapping,
          realData: actions.payload.realData,
          tableData: actions.payload.tableData,
          last_update: actions.payload.last_update,
        },
      };
    case types.GET_ALL_PENDAPATAN_FAILED:
      return {
        ...state,
        allPendapatan: {
          ...initialState.allPendapatan,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data',
          errorGet: true,
        },
      };
    case types.GET_REALISASI_BELANJA_PER_OPD_START:
      return {
        ...state,
        relisasiPerOpd: {
          ...initialState.relisasiPerOpd,
          loadingGet: true,
        },
      };
    case types.GET_REALISASI_BELANJA_PER_OPD_SUCCESS:
      return {
        ...state,
        relisasiPerOpd: {
          ...initialState.relisasiPerOpd,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data',
          data: actions.payload.data,
          pencapaian_opd: actions.payload.pencapaian_opd,
          totalFooter: actions.payload.totalFooter,
          timestamp: actions.payload.timestamp,
        },
      };
    case types.GET_REALISASI_BELANJA_PER_OPD_FAILED:
      return {
        ...state,
        relisasiPerOpd: {
          ...initialState.relisasiPerOpd,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data',
          errorGet: true,
        },
      };
    case types.GET_REALISASI_PBJ_START:
      return {
        ...state,
        realisasiPbjProses: {
          ...initialState.realisasiPbjProses,
          loadingGet: true,
        },
      };
    case types.GET_REALISASI_PBJ_SUCCESS:
      return {
        ...state,
        realisasiPbjProses: {
          ...initialState.realisasiPbjProses,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data pbj',
          data: actions.payload,
        },
      };
    case types.GET_REALISASI_PBJ_FAILED:
      return {
        ...state,
        realisasiPbjProses: {
          ...initialState.realisasiPbjProses,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data pbj',
          errorGet: true,
        },
      };
    case types.GET_REALISASI_OPD_PBJ_START:
      return {
        ...state,
        pbjDetailOpd: {
          ...initialState.pbjDetailOpd,
          loadingGet: true,
        },
      };
    case types.GET_REALISASI_OPD_PBJ_SUCCESS:
      return {
        ...state,
        pbjDetailOpd: {
          ...initialState.pbjDetailOpd,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data pbj',
          data: actions.payload.data,
          last_update: actions.payload.timestamp,
        },
      };
    case types.GET_REALISASI_OPD_PBJ_FAILED:
      return {
        ...state,
        pbjDetailOpd: {
          ...initialState.pbjDetailOpd,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data pbj',
          errorGet: true,
        },
      };

    case types.GET_PAKET_OPD_PBJ_START:
      return {
        ...state,
        pbjDetailPaketOpd: {
          ...initialState.pbjDetailPaketOpd,
          loadingGet: true,
        },
      };
    case types.GET_PAKET_OPD_PBJ_SUCCESS:
      return {
        ...state,
        pbjDetailPaketOpd: {
          ...initialState.pbjDetailPaketOpd,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data pbj',
          data: actions.payload.data,
          last_update: actions.payload.timestamp,
        },
      };
    case types.GET_PAKET_OPD_PBJ_FAILED:
      return {
        ...state,
        pbjDetailPaketOpd: {
          ...initialState.pbjDetailPaketOpd,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data pbj',
          errorGet: true,
        },
      };

    case types.GET_REALISASI_KEGIATAN_PER_OPD_START:
      return {
        ...state,
        simbangdaKegiatanOpd: {
          ...initialState.simbangdaKegiatanOpd,
          loadingGet: true,
        },
      };
    case types.GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS:
      return {
        ...state,
        simbangdaKegiatanOpd: {
          ...initialState.simbangdaKegiatanOpd,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data Kegiatan ',
          data: actions.payload.data,
          timestamp: actions.payload.timestamp,
        },
      };
    case types.GET_REALISASI_KEGIATAN_PER_OPD_FAILED:
      return {
        ...state,
        simbangdaKegiatanOpd: {
          ...initialState.simbangdaKegiatanOpd,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data',
          errorGet: true,
        },
      };

    case types.GET_GRAFIK_SIMBANGDA_START:
      return {
        ...state,
        grafikSimbangda: {
          ...initialState.grafikSimbangda,
          loadingGet: true,
        },
      };
    case types.GET_GRAFIK_SIMBANGDA_SUCCESS:
      return {
        ...state,
        grafikSimbangda: {
          ...initialState.grafikSimbangda,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data grafik ',
          data: actions.payload.data,
          last_update: actions.payload.timestamp,
        },
      };
    case types.GET_GRAFIK_SIMBANGDA_FAILED:
      return {
        ...state,
        grafikSimbangda: {
          ...initialState.grafikSimbangda,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data',
          errorGet: true,
        },
      };

    case types.GET_GRAFIK_PENDAPATAN_START:
      return {
        ...state,
        grafikPendapatanDaerah: {
          ...initialState.grafikPendapatanDaerah,
          loadingGet: true,
        },
      };
    case types.GET_GRAFIK_PENDAPATAN_SUCCESS:
      return {
        ...state,
        grafikPendapatanDaerah: {
          ...initialState.grafikPendapatanDaerah,
          loadingGet: false,
          messageGet: 'Berhasil Mengambil Data grafik pendapatan',
          data: {
            opd: actions.payload.opd,
            value: actions.payload.value,
          },
          last_update: actions.payload.timestamp,
        },
      };
    case types.GET_GRAFIK_PENDAPATAN_FAILED:
      return {
        ...state,
        grafikPendapatanDaerah: {
          ...initialState.grafikPendapatanDaerah,
          loadingGet: false,
          messageGet: 'GAGAL Mengambil Data',
          errorGet: true,
        },
      };

    case types.GET_DATA_HIBAH_START:
      return {
        ...state,
        dataHibah: {
          ...initialState.dataHibah,
          loadingGet: true,
          errorGet: false,
          messageGet: '',
        }
      }
    case types.GET_DATA_HIBAH_SUCCESS:
      return {
        ...state,
        dataHibah: {
          ...initialState.dataHibah,
          loadingGet: false,
          errorGet: false,
          messageGet: '',
          data: {
            jumlah: actions.payload.data.juml_hibah_apbd,
            nilai: actions.payload.data.anggaran_apbd,
            belum_proses: actions.payload.data.juml_hibah_belum_proses,
            proses: actions.payload.data.juml_hibah_sedang_proses,
            selesai: actions.payload.data.juml_hibah_selesai_proses
          },
          last_update: actions.payload.timestamp
        }
      }
    case types.GET_DATA_HIBAH_FAILED:
      return {
        ...state,
        dataHibah: {
          ...initialState.dataHibah,
          loadingGet: false,
          errorGet: true,
          messageGet: 'failed',
          data: {
            jumlah: 0,
            nilai: 0,
            belum_proses: 0,
            proses: 0,
            selesai: 0
          }
        }
      }
    case types.GET_OPD_PEMBERI_HIBAH_START:
      return {
        ...state,
        opdPemberiHibah: {
          ...initialState.opdPemberiHibah,
          loadingGet: true,
          errorGet: false,
          messageGet: '',
        }
      }
    case types.GET_OPD_PEMBERI_HIBAH_SUCCESS:
      return {
        ...state,
        opdPemberiHibah: {
          ...initialState.opdPemberiHibah,
          loadingGet: false,
          errorGet: false,
          messageGet: 'success',
          data: actions.payload.data,
          last_update: actions.payload.timestamp
        }
      }
    case types.GET_OPD_PEMBERI_HIBAH_FAILED:
      return {
        ...state,
        opdPemberiHibah: {
          ...initialState.opdPemberiHibah,
          loadingGet: false,
          errorGet: true,
          messageGet: 'failed',
          data: []
        }
      }
    case types.GET_DETAIL_HIBAH_OPD_START:
      return {
        ...state,
        detailHibahOpd: {
          ...initialState.detailHibahOpd,
          loadingGet: true,
          errorGet: false,
          messageGet: '',
        }
      }
    case types.GET_DETAIL_HIBAH_OPD_SUCCESS:
      return {
        ...state,
        detailHibahOpd: {
          ...initialState.detailHibahOpd,
          loadingGet: false,
          errorGet: false,
          messageGet: 'success',
          data: actions.payload.data,
          last_update: actions.payload.timestamp
        }
      }
    case types.GET_DETAIL_HIBAH_OPD_FAILED:
      return {
        ...state,
        detailHibahOpd: {
          ...initialState.detailHibahOpd,
          loadingGet: false,
          errorGet: true,
          messageGet: 'failed',
          data: [],
          last_update: 'n/a'
        }
      }
    

    default:
      return state;
  }
}
