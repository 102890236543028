import React from 'react';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAllPendapatan, getGrafikPendapatan } from '../../../redux/ducks/home/actions';
import { connect } from 'react-redux';
import CountUp from 'react-countup';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BackButton from '../../components/BackButton';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function DetailPendapatan({ targetKeseluruhan, ...props }) {
  const [popUpLogin, setPopUpLogin] = React.useState(false);
  const navigate = useNavigate();
  const { yearconfig } = useParams()
  React.useEffect(() => {
    if (targetKeseluruhan == 0) {
      navigate('/tahun/'+yearconfig);
    } else {
      props.getAllPendapatan(yearconfig);
      // props.getGrafikPendapatan();
    }
  }, [targetKeseluruhan, yearconfig]);

  const ucWord = (text) => {
    return text.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: 'Detail Pendapatan',
      isActive: false,
      goTo: '',
    },
  ];

  const _routeDetail = (type, items) => {
    if (type == 'all') {
      navigate(`/tahun/${yearconfig}/bappenda/detail-table-pendapatan/all/Keseluruhan`);
    } else {
      navigate(`/tahun/${yearconfig}/bappenda/detail-table-pendapatan/${items.kode}/${items.uraian}`);
    }
    // if (props.isLogin) {
    // } else {
    //   setPopUpLogin(true);
    // }
  };

  return (
    <Layout
      loading={props.loading}
      breadCrumbsData={breadCrumbsData}
      popUpShow={popUpLogin}
      closePopUp={() => setPopUpLogin(false)}
    >
      <div className="space-y-5">
        <div className="bg-white p-2 rounded-lg animate__animated animate__fadeIn">
          <div className="cursor-pointer">
            <div className="grid grid-rows-1 lg:grid-cols-12 xl:grid-cols-12 gap-5 items-center">
              <div className="bg-white col-span-3">
                <div className="float-left pl-16">
                  <h1 className="font-bold text-center text-xl">PENDAPATAN </h1>
                  <img src="/img/rafiki.svg" className="w-full h-32" alt="" />
                </div>
              </div>
              <div
                onClick={() => _routeDetail('all', null)}
                className="bg-white border border-primaryGreen p-5 rounded-lg col-span-3"
              >
                <span className="text-black  font-bold ">Target Keseluruhan</span>
                <br />
                <span className="text-primaryGreen text-2xl font-bold ">
                  Rp. <CountUp duration={1.75} separator="." end={targetKeseluruhan} />
                </span>
              </div>
              <div
                onClick={() => _routeDetail('all', null)}
                className="bg-white border border-primaryGreen p-5 rounded-lg col-span-3"
              >
                <span className="text-black  font-bold">Realisasi Keseluruhan</span>
                <br />
                <span className="text-primaryGreen text-2xl font-bold">
                  {' '}
                  Rp. <CountUp duration={1.75} separator="." end={props.realisasiKeseluruhan} />
                </span>
              </div>
              <div
                onClick={() => navigate(`/tahun/${yearconfig}/bappenda/detail-grafik-pendapatan`)}
                className="bg-white border border-primaryGreen p-5 h-[62%]  rounded-lg col-span-3 "
              >
                <div className="mb-3"></div>
                <span className="text-primaryGreen text-lg font-bold">Grafik Pendapatan Per OPD</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-rows-1 lg:grid-cols-3 xl:grid-cols-3 gap-2">
          {props.dataAllPendapatan.map((items, keys) => {
            return (
              <div
                key={keys}
                onClick={() => _routeDetail('loop', items)}
                className="flex flex-col bg-white rounded-xl drop-shadow-2xl animate__animated animate__fadeIn cursor-pointer"
              >
                <div>
                  <div className={`w-full py-4 px-3 bg-white rounded-t-lg`}>
                    <h1 className="text-sm font-bold">{ucWord(items.uraian)}</h1>
                  </div>
                </div>
                <div>
                  <ul>
                    {items.child.map((childItems, keysChild) => {
                      let isEven = keysChild % 2 === 0 ? true : false; // isGenap
                      return (
                        <li key={keysChild} className={'py-1 px-3'}>
                          <div className="border-b-2 border-gray-200 mb-3">
                            <div
                              className={`flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 ${
                                isEven ? `border-primaryGreen` : `border-primaryOrange`
                              } `}
                            >
                              <div className="w-[60%] font-medium text-sm">{childItems.uraian}</div>
                              <div className="space-y-2">
                                <span
                                  className={` ${
                                    isEven ? `bg-green-100` : `bg-blue-100`
                                  } py-1 px-1 text-sm text-right rounded-lg float-right`}
                                >
                                  {numberFormatter(childItems.pendapatan)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="h-full flex flex-col justify-end">
                  <div className="flex justify-between bg-green-50 px-5 py-2 rounded-b-xl">
                    <div>
                      <p className="font-bold mb-2 text-sm">Total Realisasi</p>
                      <p className="font-bold text-xs">Terakhir Update</p>
                    </div>
                    <div>
                      <p className="font-bold text-base mb-1 text-primaryGreen text-right">
                        {numberFormatter(items.pendapatan)}
                      </p>
                      <p className="font-bold text-xs text-orange-600 text-right">{items.last_update}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="bg-white p-2 rounded-lg animate__animated animate__fadeIn">
          <div className="chartBox mb-5">
            <Bar data={dataBar} options={options} />
          </div>
          <div className="flex justify-end">
            <div className="text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row pr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 text-gray-500"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-xs text-gray-600 pl-1">terakhir update : {props.lastUpdateGrafik}</span>
            </div>
          </div>
        </div> */}
      </div>
      <BackButton backLink={`/tahun/${yearconfig}`} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    dataAllPendapatan: state.homeState.allPendapatan.data,
    loading: state.homeState.allPendapatan.loadingGet,
    error: state.homeState.allPendapatan.errorGet,
    targetKeseluruhan: state.homeState.pendapatanDaerah.target,
    realisasiKeseluruhan: state.homeState.pendapatanDaerah.realisasi,
    dataGrafik: state.homeState.grafikPendapatanDaerah.data,
    lastUpdateGrafik: state.homeState.grafikPendapatanDaerah.last_update,
    isLogin: state.authState.isLogin,
  };
};

const mapDispatchToProps = {
  getAllPendapatan,
  getGrafikPendapatan,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DetailPendapatan);
