import {
  Home,
  Home2,
  Home3,
  Home4,
  LoginPage,
  LoginPage1,
  DetailPendapatan,
  DetailTablePendapatan,
  DetailRealisasiBelanjaOpd,
  DetailPbj,
  DetailPaketOpd,
  DetailKegiatanOpd,
  GrafikPendapatanPerOpd,
  Maintenance,
  DataOpdPemberiHibah,
  DetailHibahOpd,
  DataIndikatorKinerjaUtama,
  ListOpd,
  DataIkd,
  LayananDanUrusan,
  DataIndikatorKinerjaProgram,
  TemaProgramUnggulan,
  DataProgramUnggulan,
  ListOpd_CapaianPK,
  DataCapaian_PK,
  NewYearMaintenance,
  CapaianKinerjaGubernur
} from '../pages';

const routes = [
  // {
  //   key: 'page-home',
  //   name: 'Home',
  //   Component: Home,
  //   path: '',
  // },
  {
    key: 'page-home-2',
    name: 'Home2',
    Component: Home4,
    path: '',
  },
  {
    key: 'page-maintenance',
    name: 'Maintenance',
    Component: NewYearMaintenance,
    path: 'maintenance',
  },
  {
    key: 'page-login',
    name: 'Login',
    Component: LoginPage1,
    path: 'login',
  },
  {
    key: 'page-detail-pendapatan',
    name: 'DetailPendapatan',
    Component: DetailPendapatan,
    path: 'bappenda/detail-pendapatan',
  },
  {
    key: 'page-detail_table-pendapatan',
    name: 'DetailTablePendapatan',
    Component: DetailTablePendapatan,
    path: 'bappenda/detail-table-pendapatan/:type/:name',
  },
  {
    key: 'page-detail_grafik-pendapatan',
    name: 'grafikPendapatan',
    Component: GrafikPendapatanPerOpd,
    path: 'bappenda/detail-grafik-pendapatan',
  },
  {
    key: 'page-detail-realisasi-belanja-opd',
    name: 'DetailRealisasiBelanjaOpd',
    Component: DetailRealisasiBelanjaOpd,
    path: 'simbangda/detail-belanja-opd',
  },
  {
    key: 'page-detail-kegiatan-opd',
    name: 'DetailKegiatanOpd',
    Component: DetailKegiatanOpd,
    path: 'simbangda/detail-kegiatan-opd/:opdName/:opdId',
  },
  {
    key: 'DetailPbj',
    name: 'DetailPbj',
    Component: DetailPbj,
    path: 'sipedal/detail-sipedal-opd',
  },
  {
    key: 'detailPaketPbj',
    name: 'detailPaketPbj',
    Component: DetailPaketOpd,
    path: 'sipedal/detail-paket-sipedal-opd/:idsatker/:namasatker',
  },
  {
    key: 'Data-opd-pemberi-hibah-page',
    name: 'DataOpdPemberiHibah',
    Component: DataOpdPemberiHibah,
    path: 'hibah/opd-pemberi-hibah',
  },
  {
    key: 'Detail-hibah-opd-page',
    name: 'DetailHibahOpd',
    Component: DetailHibahOpd,
    path: 'hibah/detail-hibah-opd/:opdId/:opdName',
  },
  {
    key: 'Indikator-List-Opd-Page',
    name: 'IndikatorListOpd',
    Component: ListOpd,
    path: 'ikd/listopd',
  },
  {
    key: 'Ikd-Opd-Page',
    name: 'IndikatorKinerjaDaerahOpd',
    Component: DataIkd,
    path: 'ikd/data-ikd-opd/:opdId/:opdName',
  },
  {
    key: 'Ikp-Layanan-dan-Urusan-Page',
    name: 'IkpLayanandanUrusan',
    Component: LayananDanUrusan,
    path: 'ikp/layanan',
  },
  {
    key: 'Ikp-Data-Page',
    name: 'IkpData',
    Component: DataIndikatorKinerjaProgram,
    path: 'ikp/data/:urusanId/:urusanName',
  },
  {
    key: 'Progul-Tema-Page',
    name: 'ProgulTema',
    Component: TemaProgramUnggulan,
    path: 'progul/tema',
  },
  {
    key: 'Progul-Data-Page',
    name: 'ProgulData',
    Component: DataProgramUnggulan,
    path: 'progul/data/:progulId/:progulName',
  },
  {
    key: 'CapaianPK-ListOPD-Page',
    name: 'CapaianPKListOPD',
    Component: ListOpd_CapaianPK,
    path: 'cpkess/listopd',
  },
  {
    key: 'CapaianPK-EsselonII-Page',
    name: 'CapaianPKEsselonII',
    Component: DataCapaian_PK,
    path: 'cpkess/data-capaian/:opdId/:opdName',
  },
  {
    key: 'Data-Indikator-Kinerja-Utama',
    name: 'DataIndikatorKinerjaUtama',
    Component: DataIndikatorKinerjaUtama,
    path: 'iku/misi',
  },
  {
    key: 'CapaianPK-Gubernur-Page',
    name: 'CapaianPKGubernur',
    Component: CapaianKinerjaGubernur,
    path: 'cpkgub',
  },
];

export default routes;
