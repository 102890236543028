import { NavLink, useParams } from 'react-router-dom';
// import { Menu, Transition } from '@headlessui/react';
// import { Fragment, useEffect, useRef, useState } from 'react';
// import { ChevronDownIcon } from '@heroicons/react/20/solid';
// import { useDispatch, useSelector } from 'react-redux';
// import { authDestroy } from '../../../../redux/ducks/auth/action';
import PointMenu from './PointMenu';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

// import required modules
import { Navigation, FreeMode } from 'swiper/modules';
const HeaderNav = () => {
  // const { isLogin, biodata } = useSelector((state) => state.authState);
  // const dispatch = useDispatch();
  const { yearconfig } = useParams()
  const menuNavbar = [
    {
      id: 'iku',
      link: '/iku/misi',
      initial: 'IKU',
      label: 'Indikator Kinerja Utama'
    },
    {
      id: 'ikd',
      link: '/ikd/listopd',
      initial: 'IKD',
      label: 'Indikator Kinerja Daerah'
    },
    {
      id: 'ikp',
      link: '/ikp/layanan',
      initial: 'IKP',
      label: 'Indikator Kinerja Program'
    },
    {
      id: 'progul',
      link: '/progul/tema',
      initial: 'PRO',
      label: 'Program Unggulan'
    },
    {
      id: 'cpkgub',
      link: '/cpkgub',
      initial: 'PKG',
      label: 'Capaian PK Gubernur'
    },
    {
      id: 'cpkess',
      link: '/cpkess/listopd',
      initial: 'PK',
      label: 'Capaian PK Esselon II'
    }
  ]

  const [uriNow, setUriNow] = useState("")
  useEffect(() => {
    const path = window.location.pathname
    const uri = path !== "/" ? path.split('/') : []
    if(uri.length >= 2){
      setUriNow(uri[3])
    }
  },[window.location.pathname])
  return (
    <div className="shadow bg-white py-1">
      <div className="h-16 mx-auto px-5 flex items-center">
        <div className='flex flex-row gap-6 my-auto items-center flex-shrink-0'>
          <NavLink to={`/tahun/${yearconfig}`} className="text-2xl hover:text-cyan-500 transition-colors cursor-pointer flex-row hidden md:flex">
            <img className="h-10 md:h-12 mx-1" src="/img/logo.png" alt="" />
          </NavLink>
          <NavLink to={`/tahun/${yearconfig}`} className="text-2xl hover:text-cyan-500 transition-colors cursor-pointer flex flex-row md:hidden">
            <img className="h-10 md:h-12 mx-1" src="/img/logo_sumbar.png" alt="" />
          </NavLink>
          
        </div>
        <div className='flex-grow lg:w-4/5 md:w-3/5 w-4/5'>
          <Swiper
            slidesPerView={'auto'}
            navigation={true}
            freeMode={true}
            modules={[Navigation, FreeMode]}
            className='mySwiper'
          >
          <SwiperSlide className='sm:max-w-[230px] max-w-[180px]'>
                <a 
                href={'http://sumbar-center.sumbarprov.go.id'} target="_blank" rel='noreferrer'
                  className={`cursor-pointer flex flex-row gap-3 p-2 sm:w-[221px] w-[170px] mx-1 group rounded-lg items-center bg-primaryGreen hover:bg-green-700 text-white hover:font-extrabold`}>
                  <div className="flex items-center justify-center flex-shrink-0">
                      <span 
                          className={`rounded-full p-1.5 sm:text-base text-sm sm:w-11 sm:h-11 flex items-center justify-center bg-white text-green-500`}>
                              DDA
                      </span>
                  </div>
                  <span className="sm:text-sm text-xs flex-grow w-3/5">
                      Dashboard Data Analytics
                  </span>
              </a>
          </SwiperSlide>
          {
            menuNavbar.map((item) => (
              <SwiperSlide className='sm:max-w-[230px] max-w-[180px]'>
                <PointMenu link={`/tahun/${yearconfig}`+item.link} initial={item.initial} label={item.label} isActive={uriNow === item.id} />
              </SwiperSlide>
            ))
          }
          </Swiper>
        </div>
        {/* <ul className="flex items-center gap-5">
          <li>
            {isLogin ? (
              <div className="text-right">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm  text-primaryOrange font-bold hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      {biodata?.name}
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-700 hover:text-orange-800"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => dispatch(authDestroy())}
                              className={`${
                                active ? 'bg-orange-400 text-white' : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <NavLink
                to="/login"
                className="bg-primaryGreen py-2 px-3 text-white rounded-lg text-sm font-bold transition-colors"
              >
                Masuk
              </NavLink>
            )}
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default HeaderNav;
